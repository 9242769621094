import Modal from "react-bootstrap/Modal";

export default function Impressum(props) {
  return (
    <Modal
      style={{ transform: "translateY(70px)", paddingBottom: "100px" }}
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Impressum</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Martin Stangl</p>
        <p>Dachweg 21a </p>
        <p>89278 Nersingen</p>
        <p>Martin.Stangl@mst-con.com </p>
        <p>0176 353 700 20</p>
        <hr></hr>
        <p>
          <h5>HAFTUNG FÜR INHALTE</h5>
          Wir sind für eigene Inhalte verantwortlich. Wir sind jedoch nicht dazu
          verpflichtet, übertragene oder gespeicherte Informationen zu
          überwachen oder nachzuforschen, ob rechtswidrige Tätigkeiten im Spiel
          sein könnten. Die Verpflichtungen zur Entfernung oder einer Sperrung
          der Nutzung von diesen Informationen bleiben hiervon demnach also
          unberührt. Eine Haftung ist erst ab dem Zeitpunkt einer Kenntnisnahme
          einer tatsächlichen Rechtsverletzung möglich. Bei dem Bekanntwerden
          von Rechtsverletzungen solcherart werden wir diese Inhalte sofort
          löschen.
        </p>
        <p>
          <h5>HAFTUNG FÜR LINKS</h5>
          Unsere Webseite enthält Links zu externen Webseiten, auf deren Inhalte
          wir keinerlei Einfluss haben und somit auch keine Gewähr übernehmen
          können. Für die Inhalte der verlinkten Webseiten sind ausschliesslich
          deren Betreiber verantwortlich. Alle verlinkten Webseiten wurden zum
          Zeitpunkt ihrer Verlinkung selbstverständlich auf mögliche
          Rechtsverstöße überprüft. Zum Zeitpunkt ihrer Verlinkung waren
          Rechtswidrige Inhalte nirgendwo erkennbar. Eine permanente inhaltliche
          Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte
          einer Rechtsverletzung nicht umsetzbar. Bei Bekanntwerden von
          Rechtsverstössen werden wir derartige Links umgehend entfernen.
        </p>
        <p>
          <h5>URHEBERRECHT</h5>
          Die Inhalte und Werke auf dieser Webseite, insbesondere Zeichnungen,
          Grafiken, Fotos, unterliegen dem deutschen Urheberrecht. Eine
          Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung
          außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen
          Zustimmung des Urhebers. Downloads und Kopien dieser Webseite sind nur
          für den privaten, nicht jedoch für den kommerziellen Gebrauch
          gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber
          erstellt wurden, werden die Urheberrechte Dritter beachtet.
          Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten
          Sie dennoch auf eine Urheberrechtsverletzung bemerken, setzen Sie uns
          bitte dazu per Enmail in Kenntnis. Bei einem Bekanntwerden von
          Rechtsverletzungen werden wir die entsprechenden Inhalte sofort
          entfernen
        </p>
        <p>
          <h5>STREITSCHLICHTUNG</h5>
          Die Europäische Kommission stellt eine Plattform zur
          Online-Streitbeilegung (OS) bereit:
          https://ec.europa.eu/consumers/odr. Wir sind weder verpflichtet noch
          bereit, an Streitbeilegungsverfahren vor einer
          Verbraucherschlichtungsstelle teilzunehmen.
        </p>
        <p>
          <h5>BILDLIZENZEN</h5>
          https://stock.adobe.com/
        </p>
      </Modal.Body>
      {/* <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer> */}
    </Modal>
  );
}
