import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";

import LastAlwaysOpen from "./accordion";

function NavBar() {
  const navbarExpand = false;
  return (
    <>
      <Navbar
        key={navbarExpand}
        expand={navbarExpand}
        className="bg-body-primary mb-0"
        data-bs-theme="dark"
      >
        <Container fluid>
          {/* <Navbar.Brand href="#">Navbar Offcanvas</Navbar.Brand> */}
          <Navbar.Toggle
            aria-controls={`offcanvasNavbar-expand-${navbarExpand}`}
          />
          <div className="offcanvas">
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${navbarExpand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${navbarExpand}`}
              placement="end"
              style={{ backgroundColor: "white", color: "black" }}
            >
              <div style={{ height: "80px" }}></div>
              <Offcanvas.Header>
                <Offcanvas.Title
                  id={`offcanvasNavbarLabel-expand-${navbarExpand}`}
                  style={{ backgroundColor: "white", color: "#097fc2" }}
                ></Offcanvas.Title>
              </Offcanvas.Header>

              <img src="./Icons/ConnectedDots_MirrorX_MSTCON.png" />

              <Offcanvas.Body>
                <LastAlwaysOpen style={{ backgroundColor: "red" }} />
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </div>
        </Container>
      </Navbar>
    </>
  );
}

export default NavBar;
