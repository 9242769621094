import { useState, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import supabaseCLP from "../supabase";

function Kontaktformular(props) {
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [telefon, setTelefon] = useState("");
  const [personalmsg, setPersonalmsg] = useState("");
  const [service, setService] = useState(0);
  const [showInput, setShowInput] = useState(true);
  // supabase states
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);

  async function insertData(props) {
    setLoading(true);
    setError(null);
    setSuccess(false);
    const tableName = "Kontaktformular";

    console.log("log the props:");
    console.log(props);

    try {
      // read last id of existing table
      let { data: idlist, e } = await supabaseCLP
        .from(tableName)
        .select("id")
        .order("id", { ascending: false })
        .limit(1);

      let lastid = idlist[0].id;
      console.log("my lastid is: " + lastid);

      const { data, error } = await supabaseCLP.from(tableName).insert([
        {
          // id: lastid + 1,
          // created_at: new Date().toLocaleString(),
          firstname: props.firstname,
          lastname: props.lastname,
          email: props.email,
          telefone: props.telefon,
          topic: props.service,
          message: props.personalmsg,
        }, // Replace with your actual column names and values
      ]);

      if (error) throw error;

      setSuccess(true);
    } catch (error) {
      setError(error.message);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  function handleSubmit(e) {
    // no reload of the page
    e.preventDefault();
    const newContact = {
      id: Date.now(),
      firstname,
      lastname,
      email,
      telefon,
      service,
      personalmsg,
    };
    // console.log(newContact);

    // write data to supabase
    insertData(newContact);

    // write to supabase

    // clear form
    setFirstname("");
    setLastname("");
    setEmail("");
    setTelefon("");
    setService(0);
    setPersonalmsg("");

    setShowInput(false);

    //
  }

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      animation
      backdrop
      className="contact-modal"
    >
      <Modal.Header
        closeButton
        closeVariant="white"
        className="contact-modal-header"
      >
        <Modal.Title
          id="contained-modal-title-vcenter"
          style={{ marginRight: "20px" }}
        >
          MSTCON - Engineering Consulting
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {showInput ? (
          <>
            <hr />
            <form className="Kontaktformular" onSubmit={handleSubmit}>
              <body>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "end",
                    marginBottom: "60px",
                  }}
                >
                  <h4 align="right">
                    WELCHES THEMA IST FÜR SIE DERZEIT VON INTERESSE?
                  </h4>

                  <select
                    // style={{ width: "200px" }}
                    onChange={(e) => setService(Number(e.target.value))}
                    value={service}
                  >
                    <option value={1}>Projektierung</option>
                    <option value={2}>Messtechnik</option>
                    <option value={3}>Cloud Engineering</option>
                    <option value={4}>Data Science</option>
                    <option value={5}>Web-Apps</option>
                  </select>
                </div>
                <h4 align="right">Wie können wir Sie erreichen?</h4>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "30px",
                    marginBottom: "60px",
                  }}
                >
                  <input
                    type="text"
                    placeholder="Firstname"
                    value={firstname}
                    onChange={(e) => setFirstname(e.target.value)}
                    required
                  ></input>
                  <input
                    type="text"
                    placeholder="Lastname"
                    value={lastname}
                    onChange={(e) => setLastname(e.target.value)}
                    required
                  ></input>
                  <input
                    type="email"
                    placeholder="name@company.domain"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  ></input>
                  <input
                    type="tel"
                    placeholder="0123/45678910"
                    value={telefon}
                    onChange={(e) => setTelefon(e.target.value)}
                    minLength={6}
                    required
                  ></input>
                </div>
                <h4 align="right">Ihre Nachricht an uns...</h4>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "30px",
                    marginBottom: "60px",
                  }}
                >
                  <textarea
                    type="text"
                    placeholder=""
                    value={personalmsg}
                    onChange={(e) => setPersonalmsg(e.target.value)}
                    rows={5}
                    cols={25}
                  ></textarea>
                </div>
              </body>

              <footer>
                <button>submit</button>
              </footer>
            </form>
          </>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              paddingBottom: "40px",
            }}
          >
            <h4 align="center">Vielen Dank für Ihre Kontaktaufnahme!</h4>

            <p>Wir melden uns umgehend bei Ihnen zurück.</p>
            <img
              style={{ width: "50%" }}
              src="Icons/AdobeStock_335815231.jpeg"
            />
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}

export { Kontaktformular };
