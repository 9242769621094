import Carousel from "react-bootstrap/Carousel";
import "bootstrap/dist/css/bootstrap.min.css";
import { useEffect, useState } from "react";

// import ExampleCarouselImage from "components/ExampleCarouselImage";

export function CarouselnPageImageLeft({ title, content }) {
  return (
    <div style={{ marginTop: "50px", marginBottom: "100px" }}>
      <h1>{title}</h1>
      <Carousel data-bs-theme="light" className="carousel">
        {content.map((page) => (
          <Carousel.Item interval={50000} key={page.h2}>
            <div className="carousel-child">
              <div>
                <img src={page.img} alt={page.h2} />
              </div>
              <div style={{ padding: "20px" }}>
                <h2>{page.h2}</h2>
                <p>{page.text}</p>
              </div>
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
      <hr style={{ marginTop: "100px" }} />
    </div>
  );
}

export function CarouselnPageImageRight({ title, content }) {
  return (
    <div
      style={{
        marginTop: "50px",
        marginBottom: "100px",
      }}
    >
      <h1>{title}</h1>
      <Carousel
        data-bs-theme="dark"
        className="carousel"
        style={{
          color: "#334155",
          backgroundColor: "white",
        }}
      >
        {content.map((page) => (
          <Carousel.Item interval={50000} key={page.h2}>
            <div className="carousel-child">
              <div style={{ padding: "20px" }}>
                <h2>{page.h2}</h2>
                <p>{page.text}</p>
              </div>
              <div>
                <img src={page.img} alt={page.h2} />
              </div>
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
      <hr style={{ marginTop: "100px" }} />
    </div>
  );
}
