import Accordion from "react-bootstrap/Accordion";

function LastAlwaysOpen() {
  return (
    <Accordion className="custom-accordion" defaultActiveKey={["4"]} flush>
      <Accordion.Item eventKey="0">
        <Accordion.Header>
          <a href="#Mission">MISSION</a>
        </Accordion.Header>
        <Accordion.Body>
          <p>Qualität steigern</p>
          <p>Kosten senken</p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>
          <a href="#Dienstleistungen">DIENSTLEISTUNGEN</a>
        </Accordion.Header>
        <Accordion.Body>
          <p>Projektierung</p>
          <p>Messtechnik</p>
          <p>Cloud Engineering</p>
          <p>Data Science</p>
          <p>Fullstack Web-App Entwicklung</p>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="2">
        <Accordion.Header>
          <a href="#Produkt">PRODUKT</a>
        </Accordion.Header>
        <Accordion.Body>
          <p>CLP - Closed-Loop Production</p>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="3">
        <Accordion.Header>
          <a href="#Partner">PARTNER</a>
        </Accordion.Header>
        <Accordion.Body>
          <p>CoreTigo</p>
          <p>Baumer</p>
          <p>AWS</p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4">
        <Accordion.Header>KONTAKT</Accordion.Header>
        <Accordion.Body>
          <div>
            <img
              src="Icons/Martin02.png"
              style={{
                width: "50%",
                borderRadius: "200px",
                marginBottom: "40px",
                backgroundColor: "#dddd",
              }}
            />
          </div>

          <div className="contact-modal-body-item">
            <p>
              Gründer & Eigentümer
              <div style={{ display: "flex", columnGap: "10px" }}>
                <h5>Martin Stangl</h5>
                Dipl.-Ing. (FH)
              </div>
            </p>

            <p>
              Email
              <h5>Martin.Stangl@mst-con.com</h5>
            </p>

            <p>
              Telefon <h5>+49 176 70035320</h5>
            </p>
          </div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default LastAlwaysOpen;
