import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

function JustifiedTabs({ title, content }) {
  return (
    <>
      <div className="JustifiedTabs">
        <h1 style={{ marginBottom: "60px" }}>{title}</h1>
        <Tabs
          defaultActiveKey={content[0].title}
          id="justify-tab-example"
          className="mb-5"
          justify
        >
          {content.map((elem) => (
            <Tab id={elem.title} eventKey={elem.title} title={elem.title}>
              <div className="JustifiedTabs-item">
                <img src={elem.img} alt={elem.title} />
                <div>
                  {elem.text.map((li) => (
                    <p>{li}</p>
                  ))}
                </div>
              </div>
            </Tab>
          ))}
        </Tabs>
      </div>

      <hr />
    </>
  );
}

export default JustifiedTabs;
