import { useEffect, useState } from "react";
import supabaseCLP from "../supabase";
import Button from "react-bootstrap/esm/Button";

function SupabaseReadWrite() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);

  const tableName = "Userlist";

  async function loadData() {
    setLoading(true);
    setError(null);

    try {
      let { data: tableData, error } = await supabaseCLP
        .from(tableName)
        .select("*");

      if (error) throw error;

      setData(tableData);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }

  async function insertData() {
    setLoading(true);
    setError(null);
    setSuccess(false);

    try {
      // read last id of existing table
      let { data: lastid, e } = await supabaseCLP
        .from(tableName)
        .select("id")
        .order("id", { ascending: false })
        .limit(1);

      console.log("The last id in the table is:");
      console.log(lastid[0].id);

      const { data, error } = await supabaseCLP.from(tableName).insert([
        {
          id: lastid[0].id + 1,
          created: new Date().toLocaleString(),
          email: "dummy@test.com",
          username: "ghost",
        }, // Replace with your actual column names and values
      ]);

      if (error) throw error;

      setSuccess(true);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }

  //   console.log(userList);

  return (
    <div style={{ backgroundColor: "#bbbb" }}>
      <h1>Supabase Data Test Comp.</h1>
      <hr />
      <ul>
        {data.map((item, index) => (
          <li key={index}>{JSON.stringify(item)}</li>
        ))}
      </ul>
      {error && <p>Error: {error}</p>}

      {success && <p>Data inserted successfully!</p>}

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Button
          variant="primary"
          type="load"
          onClick={loadData}
          disabled={loading}
        >
          {loading ? "Loading..." : "Load Data"}
        </Button>

        <Button
          variant="danger"
          type="submit"
          onClick={insertData}
          disabled={loading}
        >
          {loading ? "Inserting..." : "Submit Data"}
        </Button>
      </div>
    </div>
  );
}

export default SupabaseReadWrite;
