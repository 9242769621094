import { useEffect, useState } from "react";
import "./style.css";

import React, { useRef } from "react";
import { useIsVisible } from "react-is-visible";
import Button from "react-bootstrap/Button";

import { CarouselnPageImageRight } from "./components/carousel";

import MultiCarousel from "./components/multicarousel";

import NavBar from "./components/navbar";
// import Kontaktformular from "./components/kontaktformular";

import { Kontaktformular } from "./components/kontaktformular";
import JustifiedTabs from "./components/tabs";

import Datenschutzerklaerung from "./components/datenschutz";
import Impressum from "./components/impressum";

import SupabaseReadWrite from "./components/supabasetest";
import supabaseCLP from "./supabase";

const CarouselPartner = [
  {
    h2: "Übertragung",
    img: "Icons/CoreTigo.png",
    text: "CoreTigo ist der weltweit führende Anbieter von Komponenten für die drahtlose Datenübertragung via IO-Link Wireless, dem speziell für die Fabrikautomation entwickelten Kommunikationsstandard der industriellen drahtlosen Kommunikation. Die drahtlosen Kommunikationslösungen von CoreTigo sind für zahlreiche Branchen geeignet, darunter die Automobilindustrie, Lebensmittel- und Getränkeindustrie, pharmazeutische Industrie und das Metallgewerbe.",
  },
  {
    h2: "Sensoren",
    img: "https://www.baumer.com/medias/sys_master/root/hf6/h8e/9080517001246/logo-baumer.svg",
    text: "Die Baumer Group ist ein führender Hersteller von Sensoren, Drehgebern, Messinstrumenten und Komponenten für die automatisierte Bildverarbeitung, wobei sich insbesondere die zahlreichen Sensoren mit IO-Link-Schnittstelle durch ihre nahtlose Kompatibilität mit den Komponenten von CoreTigo auszeichnen.",
  },

  {
    h2: "Cloud-Computing",
    img: "Icons/icon-cloud-aws.png",
    text: "Amazon Web Services (AWS) ist mit mehr als 200 Services, die umfangreiche Funktionen bieten und in global verteilten Rechenzentren bereitgestellt werden, die weltweit umfassendste und am häufigsten genutzte Cloud. Millionen von Kunden – darunter einige der am schnellsten wachsenden Startups und der größten Konzerne sowie wichtige Behörden – vertrauen auf AWS, wenn es darum geht, agiler zu werden, Kosten zu senken und Innovationen schneller zu realisieren.",
  },
];

const CarouselLösung = [
  {
    h2: "Ermöglichen",
    img: "Icons/Enable.png",
    text: "CoreTigo ist der weltweit erste Anbieter von Komponenten für die drahtlose Datenübertragung via IO-Link Wireless, dem speziell für die Fabrikautomation entwickelten Kommunikationsstandard der industriellen drahtlosen Kommunikation. Die drahtlosen Kommunikationslösungen von CoreTigo sind für zahlreiche Branchen geeignet, darunter die Automobilindustrie, Lebensmittel- und Getränkeindustrie, pharmazeutische Industrie und das Metallgewerbe.",
  },
  {
    h2: "Cloud",
    img: "Icons/cloud.jpeg",
    text: "Die Baumer Group ist ein führender Hersteller von Sensoren, Drehgebern, Messinstrumenten und Komponenten für die automatisierte Bildverarbeitung, wobei sich insbesondere die zahlreichen Sensoren mit IO-Link-Schnittstelle durch ihre nahtlose Kompatibilität mit den Komponenten von CoreTigo auszeichnen.",
  },

  {
    h2: "Open Source",
    img: "Icons/AdobeStock_526987546.png",
    text: "Amazon Web Services (AWS) ist mit mehr als 200 Services, die umfangreiche Funktionen bieten und in global verteilten Rechenzentren bereitgestellt werden, die weltweit umfassendste und am häufigsten genutzte Cloud. Millionen von Kunden – darunter einige der am schnellsten wachsenden Startups und der größten Konzerne sowie wichtige Behörden – vertrauen auf AWS, wenn es darum geht, agiler zu werden, Kosten zu senken und Innovationen schneller zu realisieren.",
  },

  {
    h2: "Messen",
    img: "Icons/Sense.png",
    text: "Amazon Web Services (AWS) ist mit mehr als 200 Services, die umfangreiche Funktionen bieten und in global verteilten Rechenzentren bereitgestellt werden, die weltweit umfassendste und am häufigsten genutzte Cloud. Millionen von Kunden – darunter einige der am schnellsten wachsenden Startups und der größten Konzerne sowie wichtige Behörden – vertrauen auf AWS, wenn es darum geht, agiler zu werden, Kosten zu senken und Innovationen schneller zu realisieren.",
  },

  {
    h2: "Data Science",
    img: "Icons/AdobeStock_416303594 (1).jpeg",
    text: "Amazon Web Services (AWS) ist mit mehr als 200 Services, die umfangreiche Funktionen bieten und in global verteilten Rechenzentren bereitgestellt werden, die weltweit umfassendste und am häufigsten genutzte Cloud. Millionen von Kunden – darunter einige der am schnellsten wachsenden Startups und der größten Konzerne sowie wichtige Behörden – vertrauen auf AWS, wenn es darum geht, agiler zu werden, Kosten zu senken und Innovationen schneller zu realisieren.",
  },
];

const CarouselAnwendungen = [
  {
    h2: "Automatisieren",
    img: "Icons/AdobeStock_311519108 (1).jpeg",
  },
  {
    h2: "Erneuern",
    img: "Icons/AdobeStock_276933391.jpeg",
  },
  {
    h2: "Sägen",
    img: "Icons/AdobeStock_292712222 (1).jpeg",
  },
  {
    h2: "Schleifen",
    img: "Icons/AdobeStock_278696793 (1).jpeg",
  },
  {
    h2: "Fräsen",
    img: "Icons/AdobeStock_296960777 (1).jpeg",
  },
  {
    h2: "Drehen",
    img: "Icons/AdobeStock_363381594 (1).jpeg",
  },

  {
    h2: "Lagern",
    img: "Icons/AdobeStock_219413198.jpeg",
  },
  {
    h2: "Industrialisieren",
    img: "Icons/AdobeStock_484882975 (1).jpeg",
  },
];

const TabsDienstleistungen = [
  {
    title: "Projektierung",
    img: "./Icons/AdobeStock_570326513 (1).jpeg",
    text: [
      <p>
        <h5>Gesamtheitliche Planung Ihrer maßgeschneiderten IoT-Lösung</h5>
        Wir entwickeln individuelle IoT-Lösungen, die perfekt auf die
        Bedürfnisse Ihres Unternehmens abgestimmt sind.
      </p>,
      <p>
        <h5>Verbindliche Meilensteine und Arbeitspakete</h5>
        Unsere detaillierte Projektplanung umfasst klare Meilensteine und
        konkrete Arbeitspakete, um den Fortschritt transparent und effizient zu
        gestalten und Sie jederzeit den Überblick über Ihr Budget behalten..
      </p>,

      <p>
        <h5>Potentialbewertung der Optimierungsmaßnahmen</h5>
        Unsere detaillierte Bewertung der individuellen Optimierungspotenziale
        gewährleistet eine fundierte Entscheidungsvorlage zur Validierung der
        anstehenden Investition.
      </p>,
    ],
  },
  {
    title: "Messtechnik",
    img: "./Icons/Messtechnik.jpeg",
    text: [
      <p>
        <h5>Bedarfsermittlung und Auswahl erforderlicher Sensoren</h5>
        Wir analysieren Ihre spezifischen Anforderungen und wählen die passenden
        Sensoren für Ihre IIoT-Anwendungen aus.
      </p>,
      <p>
        <h5>Integration spezifischer Sensorlösungen</h5>
        Unser Team integriert maßgeschneiderte Sensorlösungen nahtlos in Ihre
        bestehenden Systeme, um eine optimale Datenerfassung zu gewährleisten.
      </p>,
      <p>
        <h5>Auslegung der erforderlichen Übertragungstechnik</h5>
        Wir planen und implementieren die notwendige Übertragungstechnik, um
        eine zuverlässige und effiziente Datenkommunikation sicherzustellen.
      </p>,
    ],
  },
  {
    title: "Cloud Engineering",
    img: "./Icons/AdobeStock_548804697.jpeg",
    text: [
      <p>
        <h5>Kosteneffiziente Cloud-Dienste</h5>
        Wir bieten Ihnen wirtschaftliche Cloud-Lösungen, die speziell auf Ihre
        IIoT-Anforderungen zugeschnitten sind und Ihre laufenden Kosten auf ein
        Minimum reduzieren.
      </p>,
      <p>
        <h5>Gewährleistung der IT-Security</h5>
        Unsere Cloud-Dienste gewährleisten höchste Sicherheitsstandards, um Ihre
        Daten und Systeme zuverlässig zu schützen.
      </p>,
      <p>
        <h5>Einfacher Zugang</h5>
        Wir ermöglichen Ihnen einen unkomplizierten Zugang zu unseren
        Cloud-Diensten, damit Sie jederzeit und überall auf Ihre Daten zugreifen
        können.
      </p>,
    ],
  },
  {
    title: "Data Science",
    img: "./Icons/AdobeStock_480305431.jpeg",
    text: [
      <p>
        <h5>Das Maximum an Erkenntnissen aus Ihren Daten holen</h5>
        Wir helfen Ihnen, den vollen Wert Ihrer Daten zu entfalten und wertvolle
        Einblicke für Ihr Unternehmen zu gewinnen.
      </p>,
      <p>
        <h5>Klare Visualisierung der Erkenntnisse</h5>
        Unsere Data-Science-Lösungen bieten Ihnen eine verständliche und
        ansprechende Visualisierung der gewonnenen Erkenntnisse, um fundierte
        Entscheidungen zu erleichtern.
      </p>,
      <p>
        <h5>Einsatz moderner Open-Source-Lösungen</h5>
        Durch den Einsatz moderner Open-Source-Technologien vermeiden wir
        unnötige Lizenzkosten und bieten Ihnen gleichzeitig leistungsstarke und
        flexible Lösungen.
      </p>,
    ],
  },
  {
    title: "Web-Apps",
    img: "./Icons/AdobeStock_213205204.jpeg",
    text: [
      <p>
        <h5>Alles aus einer Hand</h5>
        Wir entwickeln für Sie Ihre individuelle Web-Anwendung Ihrer IoT-Lösung
        – von der Planung bis zur Umsetzung, alles aus einer Hand und auf allen
        Endgeräten lauffähig.
      </p>,
      <p>
        <h5>Individuelles Design und Funktionalität</h5>
        Unsere maßgeschneiderten Designs und Funktionen sind perfekt auf die
        Bedürfnisse Ihres Unternehmens abgestimmt.
      </p>,
      <p>
        <h5>Leistungsstark</h5>
        Unsere Web-Apps sind leistungsstark und zuverlässig, um den
        Echtzeit-Anforderungen Ihrer IIoT-Anwendungen gerecht zu werden.
      </p>,
    ],
  },
];

function App() {
  const [userList, setUserList] = useState([]);
  const [modalShow, setModalShow] = useState(false);

  const [impressumShow, setImpressumShow] = useState(false);
  const [datenschutzerklaerungShow, setDSEShow] = useState(false);

  if (true) {
    console.log("zeige Datenschutz? " + datenschutzerklaerungShow);
  }

  // check if component is visible
  const nodeRef = useRef();
  // const isVisible = useIsVisible(nodeRef);

  // console.log(isVisible);

  // load database content once
  useEffect(function () {
    async function getUserlist() {
      const { data: Userlist, error } = await supabaseCLP
        .from("Userlist")
        .select("*");
      setUserList(Userlist);
    }
    getUserlist();
  }, []);

  if (userList === []) {
    console.log("userList is empty...");
  } else {
    console.log("Supabase data successfully read");
    console.log(userList);
  }

  return (
    <>
      {!modalShow ? <Header /> : <></>}

      <div className="container">
        <div style={{ height: "110px" }}></div>

        {/* <SupabaseReadWrite /> */}
        {/* <div style={{ color: "black" }}>
          <p>{userList[0].email}</p>
        </div> */}
        <div id="Mission">
          <ImgCenterTextLeftRight title={"MISSION"} />
          <MultiCarousel content={CarouselAnwendungen} />
        </div>
        <hr />
        {/* <h1 ref={nodeRef}>{isVisible && `I'm visible!`}</h1> */}

        <div id="Dienstleistungen">
          <JustifiedTabs
            title={"DIENSTLEISTUNGEN"}
            content={TabsDienstleistungen}
          />
        </div>

        {/* Produkt */}
        <div id="Produkt">
          <CLPInit />
        </div>
        <div id="Partner">
          <CarouselnPageImageRight title="Partner" content={CarouselPartner} />
        </div>
      </div>
      <FloatingContact setModalShow={setModalShow} />
      {modalShow ? (
        // <Kontaktformular show={modalShow} onHide={() => setModalShow(false)} />
        <Kontaktformular show={modalShow} onHide={() => setModalShow(false)} />
      ) : (
        <></>
      )}

      <Footer setImpressumShow={setImpressumShow} setDSEShow={setDSEShow} />
      {impressumShow ? (
        <Impressum
          show={impressumShow}
          onHide={() => setImpressumShow(false)}
        />
      ) : (
        <></>
      )}
      {datenschutzerklaerungShow ? (
        <Datenschutzerklaerung
          show={datenschutzerklaerungShow}
          onHide={() => setDSEShow(false)}
        />
      ) : (
        <></>
      )}
    </>
  );
}

function Header() {
  return (
    <header className="header">
      <div className="logo">
        <img
          src="Icons/MSTCON_Icon.png"
          alt="MSTCON Logo"
          style={{ width: "130px" }}
          onClick={() => (window.location.href = "https://www.mst-con.com")}
        />
      </div>
      <div></div>
      <div className="navbar">
        <NavBar />
      </div>
    </header>
  );
}

function Loader() {
  return <p>Loading</p>;
}

function ImgCenterTextLeftRight({ title }) {
  return (
    <>
      <div className="ImgCenterTextLeftRight-flex">
        <h1 style={{ marginBottom: "60px" }}>{title}</h1>
        <div className="ImgCenterTextLeftRight-grid">
          <div>
            <h2>Qualität steigern</h2>
            <ul className="myul">
              <li>
                <div>
                  Optimierte Produktionsqualität durch <b>Mustererkennung</b>{" "}
                  und Analyse von Abweichungen in <b>Echtzeit</b>
                </div>
                <div>
                  <img src="./Icons/Symbole/Mustererkennung.png" alt="muster" />
                </div>
              </li>
              <li>
                <div>
                  Automatisierte Bewertung von <b>Korrekturmaßnahmen</b>
                </div>
                <div>
                  <img src="./Icons/Symbole/Maßnahme.png" alt="maßnahme" />
                </div>
              </li>
              <li>
                <div>
                  Cloudbasierte, <b>lückenlosen Dokumentation</b>, jederzeit und
                  weltweit abrufbar
                </div>
                <div>
                  <img src="./Icons/Symbole/Dokumentation.png" alt="doku" />
                </div>
              </li>
            </ul>
          </div>
          <div className="ImgCenterTextLeftRight-flex">
            <img
              src="./Icons/AdobeStock_720246560.jpeg"
              alt="Productivity"
              style={{ marginBottom: "40px" }}
            />
          </div>
          <div>
            <h2>Kosten senken</h2>
            <ul className="myul">
              <li>
                <div>
                  <b>Überprüfung des Warenstroms</b> und Produktionsfortschritts
                  und <b> kontinuierlicher Abgleich</b> mit der Planung
                </div>
                <div>
                  <img src="./Icons/Symbole/Wertstrom.png" alt="wert" />
                </div>
              </li>
              <li>
                <div>
                  Engpassmaschinen an Hand von Live-Daten identifizieren und{" "}
                  <b>Maßnahmen in Echtzeit </b> einleiten
                </div>
                <div>
                  <img src="./Icons/Symbole/Zeit.png" alt="zeit" />
                </div>
              </li>
              <li>
                <div>
                  <b>Cloudbasierte Vernetzung</b> relevanter Prozessdaten
                </div>
                <div>
                  <img src="./Icons/Symbole/Vernetzt.png" alt="netz" />
                </div>
              </li>
            </ul>
          </div>
        </div>
        <h2>in ihrer Produktion beim</h2>
        {/* <PictureShowNPages title={""} content={CarouselAnwendungen} /> */}
      </div>
    </>
  );
}

function FloatingContact({ setModalShow }) {
  return (
    <div className="floating-contact">
      <img
        src="./Icons/Kontakt_blue_filled.png"
        onClick={() => setModalShow(true)}
        alt="FloatingContactIcon"
      />
    </div>
  );
}

function CLPInit() {
  return (
    <>
      <div className="CLPLogin">
        <h1 style={{ marginBottom: "60px" }}>PRODUKT</h1>
        <img
          src="./Icons/CLPPress2StartLoop_2.gif"
          alt="CLP-PressInitGif"
          onClick={() => window.open("https://clp.mst-con.com", "_blank")}
        />
        {/* <button onClick={() => setCLPShow(true)}>
          <h1>Start</h1>
        </button> */}
      </div>
      <hr />
    </>
  );
}

function Footer({ setImpressumShow, setDSEShow }) {
  return (
    <header className="footer">
      <div>
        <p>© 2024 Copyright: MSTCON</p>
      </div>
      <div className="logo">
        <img
          src="Icons/MSTCON_Icon.png"
          alt="MSTCON Logo"
          style={{ width: "130px" }}
          onClick={() => (window.location.href = "https://www.mst-con.com")}
        />
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Button onClick={() => setImpressumShow(true)}>Impressum</Button>
          <Button onClick={() => setDSEShow(() => true)}>
            Datenschutzerklaerung
          </Button>
        </div>
      </div>

      <div className="logo">
        <div>
          <img
            src="Icons/Linkedin.png"
            alt="LinkedIn Logo"
            onClick={() =>
              window.open("https://www.linkedin.com/company/mstcon/", "_blank")
            }
          />
          <img
            src="Icons/github-icon.png"
            alt="Github Logo"
            onClick={() => window.open("https://github.com/MSt-CON", "_blank")}
          />
        </div>
      </div>
    </header>
  );
}

export default App;
