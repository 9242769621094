import Carousel from "react-multi-carousel";
import WithStyles from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

// Source:
// https://www.npmjs.com/package/react-multi-carousel

function MultiCarousel({ content }) {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <Carousel
      responsive={responsive}
      additionalTransfrom={0}
      arrows
      autoPlay={true}
      autoPlaySpeed={3000}
      infinite
      showDots={false}
    >
      {content.map((page) => (
        <>
          <div className="MultiCarousel">
            <img src={page.img} alt={page.h2} />
            <h3>{page.h2}</h3>
          </div>
        </>
      ))}
    </Carousel>
  );
}

export default MultiCarousel;
